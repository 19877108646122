<!--
 * @Description: 报价填写
 * @Author: zhang zhen
 * @Date: 2023-04-26 17:17:03
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-04-27 09:56:33
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePriceForm.vue
-->
<template>
  <a-card title="填写报价" class="OrganizationInformation">
    <a-form-model
      v-bind="{
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      }"
    >
      <div class="cover-box">
        <a-form-model-item label="报价类型" class="inlineForm" labelAlign="left">
          <j-dictSelect v-model="form.priceType" dictCode="1200" placeholder="报价类型" />
        </a-form-model-item>
        <a-form-model-item label="是否含税" class="inlineForm" labelAlign="left">
          <j-dictSelect v-model="form.hasTask" dictCode="1200" placeholder="是否含税" />
        </a-form-model-item>
        <a-form-model-item label="是否包括运输" class="inlineForm" labelAlign="left">
          <j-dictSelect v-model="form.a" dictCode="1200" placeholder="是否包括运输" />
        </a-form-model-item>
        <a-form-model-item label="运费" class="inlineForm" labelAlign="left">
          <a-input placeholder="运费" />
        </a-form-model-item>
        <a-form-model-item label="是否可提供发票" class="inlineForm" labelAlign="left">
          <j-dictSelect v-model="form.b" dictCode="1200" placeholder="是否可提供发票" />
        </a-form-model-item>
        <a-form-model-item label="厂家所在区域：" class="inlineForm" labelAlign="left">
          <j-dictSelect v-model="form.c" dictCode="1007" placeholder="厂家所在区域：" />
        </a-form-model-item>
        <a-form-model-item label="产品单价" class="inlineForm" labelAlign="left">
          <a-input placeholder="产品单价" />
        </a-form-model-item>
        <a-form-model-item label="产品总价" class="inlineForm" labelAlign="left">
          <a-input placeholder="产品总价" />
        </a-form-model-item>
      </div>
      <div class="cover-box">
        <a-form-model-item
          label="报价说明"
          labelAlign="left"
          v-bind="{
            labelCol: { span: 4 },
            wrapperCol: { span: 18 }
          }"
        >
          <a-textarea placeholder="请输入报价说明" allow-clear :autosize="{ minRows: 7, maxRows: 7 }" />
          <a-row :span="8">
            <a-col :span="6">
              <a-button type="primary" @click="handleDownloadFile">下载报价参数模板</a-button>
            </a-col>
            <a-col :span="18" style="text-align: right;">
              <uploadFile uploadType="text" :number="1" />
            </a-col>
          </a-row>
        </a-form-model-item>
      </div>
      <div class="cover-box">
        <h3 class="title">服务与售后</h3>
        <a-form-model-item
          label="交易保障"
          labelAlign="left"
          v-bind="{
            labelCol: { span: 4 },
            wrapperCol: { span: 18 }
          }"
        >
          <div class="tag-list">
            <div class="tag-list-item" :class="tagARow.includes(i.value) && 'active'" v-for="i in tagListA" :key="i.value" @click="handleSaveSelection('tagARow', i.value)">
              {{ i.label }}
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="特色服务"
          labelAlign="left"
          v-bind="{
            labelCol: { span: 4 },
            wrapperCol: { span: 18 }
          }"
        >
          <div class="tag-list">
            <div class="tag-list-item" :class="tagBRow.includes(i.value) && 'active'" v-for="i in tagListB" :key="i.value" @click="handleSaveSelection('tagBRow', i.value)">
              {{ i.label }}
            </div>
          </div>
        </a-form-model-item>
      </div>
      <div class="options">
        <a-button type="primary">提交，预览</a-button>
        <a-button>取消</a-button>
      </div>
    </a-form-model>
  </a-card>
</template>

<script>
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import uploadFile from '@/components/plugins/uploadFile'
export default {
  name: 'quotePriceForm',
  components: {
    JDictSelect,
    uploadFile
  },
  data() {
    return {
      loading: false, // 上传的loading
      form: {},
      tagARow: [],
      tagBRow: [],
      tagListA: [
        {
          label: '方案不满意退款',
          value: '11'
        },
        {
          label: '电话随时联系',
          value: '12'
        },
        {
          label: '14天交货',
          value: '13'
        }
      ],
      tagListB: [
        {
          label: '来样加工',
          value: '21'
        },
        {
          label: '免费定制',
          value: '22'
        }
      ]
    }
  },
  methods: {
    /* 下载模板 */
    handleDownloadFile() {
      // TODO 地址
      window.open('')
    },
    /* 存储 */
    handleSaveSelection(key, value) {
      if (!this[key].includes(value)) {
        this[key].push(value)
      } else {
        this[key] = this[key].filter(i => i != value)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-card {
  width: 60%;
  border-radius: 8px;
  background: #fffff8;
  .cover-box {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 4px;
    .title {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .inlineForm {
    width: 500px;
  }
  .tag-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px 24px;
    &-item {
      border-radius: 6px;
      width: 140px;
      height: 60px;
      text-align: center;
      border: 1px solid #8fc6fc;
      color: #53a6fa;
      cursor: pointer;
      line-height: 58px;
      background-color: #e4f2fe;
      font-size: 12px;
      &:hover {
        color: #fff;
        background-color: #1890ff;
      }
      &.active {
        color: #fff;
        background-color: #1890ff;
      }
    }
  }
}
.options {
  text-align: center;
  .ant-btn {
    &+.ant-btn {
      margin-left: 8px;
    }
  }
}
</style>
